<template>
  <div class="page-wrapper mt-3">
    <div v-if="loaded">
      <no-access v-if="!hasAccess"></no-access>
      <access v-else></access>
    </div>
  </div>
</template>

<style></style>

<script>
import Access from "../../components/Reputation/Access.vue";
import NoAccess from "../../components/Reputation/NoAccess.vue";
import ReputationManagementService from "../../services/ReputationManagementService";
import TrackingService from "../../services/TrackingService";

export default {
  name: "Reputation.Index",
  components: { NoAccess, Access },
  data: () => {
    return {
      loaded: false,
      hasAccess: false,
    };
  },
  async mounted() {
    this.hasAccess = await ReputationManagementService.hasAccess(
      this.$auth.activeCompany
    );

    this.loaded = true;

    TrackingService.track("viewed reputation management page");
  },
};
</script>

<template>
  <div class="container-xl">
    <div class="row">
      <aside class="col-2">
        <div class="container-xl">
          <div class="page-header">
            <div class="page-pretitle">Reputation Management</div>
          </div>

          <ul class="nav flex-column">
            <li class="nav-item">
              <router-link class="nav-link" to="/reputation/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler me-1 icon-tabler-comet"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M15.5 18.5l-3 1.5l.5 -3.5l-2 -2l3 -.5l1.5 -3l1.5 3l3 .5l-2 2l.5 3.5z"
                  ></path>
                  <line x1="4" y1="4" x2="11" y2="11"></line>
                  <line x1="9" y1="4" x2="12.5" y2="7.5"></line>
                  <line x1="4" y1="9" x2="7.5" y2="12.5"></line>
                </svg>
                Reviews
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/reputation/search-score">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-shield-check me-2"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 12l2 2l4 -4"></path>
                  <path
                    d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3"
                  ></path>
                </svg>
                Trust Insights
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/reputation/integrations">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler me-1 icon-tabler-puzzle"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M4 7h3a1 1 0 0 0 1 -1v-1a2 2 0 0 1 4 0v1a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h1a2 2 0 0 1 0 4h-1a1 1 0 0 0 -1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-1a2 2 0 0 0 -4 0v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h1a2 2 0 0 0 0 -4h-1a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1"
                  ></path>
                </svg>
                Integrations
              </router-link>
            </li>
          </ul>
          <div calss="page-header" v-if="$auth.superuser">
            <div class="page-pretitle">Administration</div>
          </div>
          <ul class="nav flex-column" v-if="$auth.superuser">
            <li class="nav-item">
              <router-link class="nav-link" to="/reputation/review-flows">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler me-1 icon-tabler-comet"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M15.5 18.5l-3 1.5l.5 -3.5l-2 -2l3 -.5l1.5 -3l1.5 3l3 .5l-2 2l.5 3.5z"
                  ></path>
                  <line x1="4" y1="4" x2="11" y2="11"></line>
                  <line x1="9" y1="4" x2="12.5" y2="7.5"></line>
                  <line x1="4" y1="9" x2="7.5" y2="12.5"></line>
                </svg>
                Surveys
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/reputation/templates">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler me-1 icon-tabler-files"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M15 3v4a1 1 0 0 0 1 1h4"></path>
                  <path
                    d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z"
                  ></path>
                  <path
                    d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2"
                  ></path>
                </svg>
                Templates
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/reputation/workflows">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler me-1 icon-tabler-exchange"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <circle cx="5" cy="18" r="2"></circle>
                  <circle cx="19" cy="6" r="2"></circle>
                  <path d="M19 8v5a5 5 0 0 1 -5 5h-3l3 -3m0 6l-3 -3"></path>
                  <path d="M5 16v-5a5 5 0 0 1 5 -5h3l-3 -3m0 6l3 -3"></path>
                </svg>
                Workflows
              </router-link>
            </li>
          </ul>
        </div>
      </aside>
      <div class="col-10">
        <div>
          <div class="container-fluid">
            <router-view
              :tutorial="tutorial"
              @tutorial-cohort="tutorialCohortE"
            ></router-view>
          </div>
        </div>
      </div>
    </div>

    <v-tour
      name="getting_started_tutorial"
      :steps="tutorial_steps"
      :options="{ highlight: true }"
      :callbacks="tutorial_callbacks"
    >
    </v-tour>
  </div>
</template>

<style lang="scss">
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
</style>

<script>
import ReputationManagementService from "../../services/ReputationManagementService";
export default {
  name: "reputation.access",
  methods: {
    onNextStepTutorial(currentStep) {
      // Just make sure to execute the route change only if it's the last step of the current page, here for example index = 2 (so it's step 3, the callback will be called from step 3 to 4)
      console.log(currentStep);
    },
    onFinishTutorial() {
      console.log("tutorial finished");
      this.tutorial = false;
    },
    onSkipTutorial() {
      this.onFinishTutorial();
    },
    onStartTutorial() {
      this.tutorial = true;
    },
    tutorialCohortE(c) {
      this.tutorialCohort = c;
    },
    async loadCompany() {
      this.company = await ReputationManagementService.getCompany(
        this.$auth.activeCompany
      );
    },
    hasFeatureFlag(flag) {
      return this.company.feature_flags
        .map((f) => f.feature_flag.slug)
        .includes(flag);
    },
  },
  data() {
    return {
      tutorial: false,
      tutorialCohort: null,
      tutorial_callbacks: {
        onNextStep: this.onNextStepTutorial,
        onFinish: this.onFinishTutorial,
        onSkip: this.onSkipTutorial,
        onStart: this.onStartTutorial,
      },
      tutorial_steps: [
        {
          target: '[data-v-step="1"]',
          content:
            "<b>Congrats!</b> Your first survey has been created!<br>Let's learn about what you can do next.",
          params: {
            placement: "bottom",
          },
        },
        {
          target: '[data-v-step="2"]',
          content:
            "You can share your survey directly to customers by sharing your QR code or copying your survey URL.",
          params: {
            placement: "left",
          },
        },
        {
          target: '[data-v-step="3"]',
          content:
            "Your survey groups contain customers you want to contact, requesting they leave your company a review.<br><b>Lets take a look at your survey group</b>",
          params: {
            placement: "top",
          },
        },
        {
          target: '[data-v-step="4"]',
          content: 'Click the survey group name or press "next" to continue.',
          params: {
            placement: "top",
          },
        },
        {
          target: '[data-v-step="5"]',
          content: "These are the members of your survey group.",
          params: {
            placement: "top",
          },
          before: () => {
            if (!this.$route.name == "reputation.cohort-detail") {
              this.$router.push({
                path: `/reputation/review-flow/${this.tutorialCohort.review_flow}/cohort/${this.tutorialCohort.id}?tutorial=1`,
              });
            }
          },
        },
        {
          target: '[data-v-step="6"]',
          content:
            "This is your survey group's <b>workflow</b>. A workflow automates sending emails and collecting reviews from your group's members.",
          params: {
            placement: "left",
          },
        },
        {
          target: '[data-v-step="7"]',
          content:
            "Each row of the member table contains important information about your group's members.",
          params: {
            placement: "top",
          },
        },
        {
          target: '[data-v-step="8"]',
          content:
            "A member's progress in the workflow is indicated by this dot. Hovering over the dot provides additional details about what stage of the workflow this member is currently in.",
          params: {
            placement: "top",
          },
        },
        {
          target: '[data-v-step="9"]',
          content: "You can edit a member's information using this tool.",
          params: {
            placement: "top",
          },
        },
        {
          target: '[data-v-step="10"]',
          content:
            "In the member editor tool, you can make changes to the member's information.",
          params: {
            placement: "right",
          },
          before: () => {
            this.$bvModal.show("new-member-modal");
          },
        },
        {
          target: '[data-v-step="11"]',
          content:
            "Members have required fields. These fields have pre-set names, but you can enter any value.",
          params: {
            placement: "left",
          },
        },
        {
          target: '[data-v-step="12"]',
          content:
            "Additionally, you can add any other fields to a member you like in an additional field. You can choose the field name and value.",
          params: {
            placement: "right",
          },
        },
        {
          target: '[data-v-step="13"]',
          content:
            "After you're done editing, you can save the member. If you didn't make any changes, just hit cancel.",
          params: {
            placement: "top",
          },
        },
        {
          target: '[data-v-step="14"]',
          content:
            "To return to your survey overview page, just click the survey name.",
          params: {
            placement: "bottom",
          },
          before: () => {
            this.$bvModal.hide("new-member-modal");
          },
        },
      ],
      company: null,
    };
  },
  async mounted() {
    await this.loadCompany();
  },
};
</script>

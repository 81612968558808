<template>
  <div class="page-body">
    <div class="container-xl">
      <!-- Page title -->
      <div class="page-header d-print-none">
        <div class="row align-items-center">
          <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-comet"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M15.5 18.5l-3 1.5l.5 -3.5l-2 -2l3 -.5l1.5 -3l1.5 3l3 .5l-2 2l.5 3.5z"
                ></path>
                <line x1="4" y1="4" x2="11" y2="11"></line>
                <line x1="9" y1="4" x2="12.5" y2="7.5"></line>
                <line x1="4" y1="9" x2="7.5" y2="12.5"></line>
              </svg>
              Reputation Management
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-6">
          <div class="card">
            <div class="card-body">
              <h2 class="text-center">Get Started with BusinessChatter&reg;</h2>
              <p class="text-center subheader">
                Collect and manage consumer reviews about your company and
                products.
              </p>

              <fieldset>
                <div class="mb-3">
                  <label class="form-label"> Company Logo: </label>
                  <drag-and-drop-upload-card
                    v-model="uploadedImage"
                    :label="`company logo`"
                    :helpText="`Logo will be resized to fit 240px high by 240px wide.`"
                    :contentTypes="['png', 'jpg', 'jpeg', 'gif', 'svg']"
                    :inlinePreview="true"
                    :uniqueId="`content-file`"
                    :uploadTo="`images`"
                    :resizeImage="true"
                    :maxHeight="240"
                    :maxWidth="240"
                    :displayWidth="120"
                    :displayHeight="120"
                  ></drag-and-drop-upload-card>
                </div>

                <div>
                  <button
                    :class="[
                      'btn',
                      'btn-primary',
                      uploadedImage && uploadedImage.baseUrl ? '' : 'disabled',
                    ]"
                    @click.prevent="setup"
                  >
                    Get Started
                  </button>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
        <div class="col-6 text-center d-flex align-items-center">
          <img src="@/assets/lp/reviews.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.subheader {
  text-transform: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}
</style>

<script>
import DragAndDropUploadCard from "../../components/Content/Upload/DragAndDropUploadCard.vue";
import ReputationManagementService from "../../services/ReputationManagementService";
import TrackingService from "../../services/TrackingService";

export default {
  name: "Reputation.Components.NoAccess",
  components: {
    DragAndDropUploadCard,
  },
  data() {
    return {
      uploadedImage: null,
    };
  },
  methods: {
    async setup() {
      let logo = this.uploadedImage;
      const company = this.$auth.activeCompany;
      company.image = logo.baseUrl;
      TrackingService.track("businesschatter setup complete");
      await ReputationManagementService.addCompany(company);
      window.location.reload();
    },
  },
};
</script>
